<template>
	<div class="baseInfo">
		<p>
			<span class="title">老人姓名：</span><span>{{ baseInfo.userName ? baseInfo.userName : '--' }}</span>
		</p>
		<p>
			<span class="title">联系方式：</span><span>{{ baseInfo.phone ? baseInfo.phone : '--' }}</span>
		</p>
		<!-- <p>
			<span class="title">街道：</span><span>{{ baseInfo.street ? baseInfo.street : '--' }}</span>
		</p>
		<p>
			<span class="title">社区：</span><span>{{ baseInfo.community ? baseInfo.community : '--' }}</span>
		</p> -->
		<p>
			<span class="title">所属组织：</span><span>{{ baseInfo.tenantName ? `${baseInfo.tenantName}/${baseInfo.organizationName}` : '--' }}</span>
		</p>
		<p>
			<span class="title">详细地址：</span><span>{{ baseInfo.detaAddress ? baseInfo.detaAddress : '--' }}</span>
		</p>
		<p>
			<span class="title">申请时间：</span><span>{{ userInfo.applyDate ? userInfo.applyDate : '--' }}</span>
		</p>
		<p>
			<span class="title">身份证：</span><span>{{ baseInfo.cardId ? baseInfo.cardId : '--' }}</span>
		</p>
		<p>
			<span class="title">服务机构：</span><span>{{ baseInfo.organName ? baseInfo.organName : '--' }}</span>
		</p>
		<p>
			<span class="title">服务补贴标准：</span><span>{{ baseInfo.serviceSubsidy ? `${baseInfo.serviceSubsidy}%` : '--' }}</span>
		</p>
		<p>
			<span class="title">服务最高标准：</span><span>{{ baseInfo.serviceMaxFee ? `${baseInfo.serviceMaxFee}（元）` : '--' }}</span>
		</p>
		<p>
			<span class="title">老人能力评估：</span><span>{{ getContent(ELDERLYABILITY, baseInfo.elderlyAbility, 'label', 'text') || '--' }}</span>
		</p>
		<p>
			<span class="title">老人类型：</span><span>{{ getContent(ELDERLYTYPE, baseInfo.elderlyType, 'label', 'text') || '--' }}</span>
		</p>
		<p>
			<span class="title">签约状态：</span><span>{{ getContent(SIGNOPTIONS, userInfo.signStatus, 'label', 'text') || '--' }}</span>
		</p>
		<p>
			<span class="title">建设状态：</span>
			<span>{{ getContent(BUILDALLSTATE, baseInfo.status || baseInfo.bedStatus, 'label', 'text') || '--' }}</span>
		</p>
		<p>
			<span class="title">服务状态：</span><span>{{ getContent(SERVICEOPTIONS, baseInfo.serviceStatus, 'label', 'text') || '--' }}</span>
		</p>
		<p>
			<span class="title">建设清单自查：</span><span>{{ userInfo.checkSelf || '--' }}</span>
		</p>
		<div style="display: flex">
			<span class="title">附件：</span>
			<div class="file" v-if="baseInfo.allFiles">
				<el-tooltip class="item" effect="dark" content="点击查看" placement="top-end">
					<a @click="seeImage(baseInfo.allFiles)">查看协议图片</a>
				</el-tooltip>
				<el-tooltip class="item" effect="dark" content="点击下载" placement="top-end">
					<a type="primary" @click="downFile">查看附件</a>
				</el-tooltip>
			</div>
			<span v-else>--</span>
		</div>
		<!-- 查看图片弹窗 -->
		<ImgaeDialog v-if="imageVisible" :imageVisible.sync="imageVisible" :imagesList="imagesList" />
	</div>
</template>

<script>
import { getContent, downloadFile } from '@/utils/index'
import { userInfo } from '@/api/bedManage'
import { SIGNOPTIONS, BUILDALLSTATE, ELDERLYABILITY, ELDERLYTYPE, SERVICEOPTIONS } from '@/data/dataDictionary'
export default {
	// 床位详情--基本信息
	name: 'baseInfo',
	props: {
		baseInfo: {
			// 基本信息--床位列表传过来的
			type: Object,
			default: () => {
				return {}
			},
		},
	},
	data() {
		return {
			SERVICEOPTIONS,
			SIGNOPTIONS,
			ELDERLYABILITY,
			ELDERLYTYPE,
			BUILDALLSTATE,
			userInfo: {},
			imageVisible: false,
			imgsList: [],
		}
	},
	mounted() {
		this.getInfo()
	},
	methods: {
		getContent,
		// 查看图片
		seeImage(imgs) {
			if (imgs && imgs.imgAddress.length > 0) {
				const imgsList = imgs.imgAddress.map((item) => {
					return { url: item.value }
				})
				this.imagesList = imgsList
				this.imageVisible = true
			} else {
				this.$message.info('暂无图片')
			}
		},
		downFile() {
			const href = this.baseInfo.allFiles.fileAddress.value
			downloadFile(href, href)
		},
		getInfo() {
			userInfo({ pensionBedId: this.baseInfo.id }).then((res) => {
				this.userInfo = res.data
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.baseInfo {
	p,
	div {
		padding: 0 60px;
		color: #999;
		font-size: 16px;
		.title {
			display: inline-block;
			width: 15%;
			color: #333333;
			min-width: 160px;
			// text-align: right;
		}
		.file {
			display: flex;
			justify-content: left;
			flex-direction: column;
			padding: 0;
			a {
				color: #3fcf89;
				margin-bottom: 15px;
				cursor: pointer;
			}
		}
	}
}
</style>
